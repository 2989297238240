import React, { Component, Suspense } from 'react';
import Sticky from 'react-stickynode';
import './App.css';

const Header = React.lazy(() => import('./header'));
const Home = React.lazy(() => import('./home'));
const About = React.lazy(() => import('./about'));
const Work = React.lazy(() => import('./work'));
const PersonalProjects = React.lazy(() => import('./personal-projects'));
const Contact = React.lazy(() => import('./contact'));
const Footer = React.lazy(() => import('./footer'));



class App extends Component {
    render() {
        return (

            <Suspense fallback={<div>Loading...</div>}>
                <Home />
                <Sticky enabled={true} innerZ={10000}>
                    <Header />
                </Sticky>
                <About />
                <Work />
                <PersonalProjects />
                <Contact />
                <Footer />
            </Suspense>


        );

    }
}

export default App;